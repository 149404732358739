<template>
  <div class="misc-wrapper">
    <b-link
      class="brand-logo"
      @click="
        $router.push({
          name: 'home',
        })
      "
    >
      <tempo-ratio-logo />
      <h2 class="brand-text text-primary ml-1">Tempo<span>Ratio</span></h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h1 class="mb-1">Sie sind nicht autorisiert! 🔐</h1>
        <b-img fluid :src="imgUrl" alt="Not authorized page" />
        <b-card>
          <p class="mb-2">
            Sie haben keine Zugriffsberechtigung für diese Seite ✋!
          </p>
          <b-button
            variant="primary"
            class="mb-1 btn-sm-block"
            :to="{ path: '/' }"
            >Zurück zum Dashboard</b-button
          >
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton, BCard } from 'bootstrap-vue';
import TempoRatioLogo from '@/layouts/components/TempoRatioLogo.vue';
import store from '@/store/index';

export default {
  components: {
    BCard,
    BLink,
    BImg,
    BButton,
    TempoRatioLogo,
  },
  data() {
    return {
      downImg: require('@/assets/images/error/nicholson.gif'),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/error/nicholson.gif');
        return this.downImg;
      }
      return this.downImg;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
h2 span {
  color: #6e6b7b;
}
</style>
